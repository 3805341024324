<template>
  <div class="mx-5 my-5">
    <h2>رصيدك الحالي</h2>
    <v-row class="mt-2">
      <v-col class="py-0 mt-2" :cols="3">
        <p
          style="
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 10px;
            color: #000;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
          "
        >
          770 ريال
        </p>
      </v-col>
    </v-row>
    <h3 class="mt-3">سجل العمليات</h3>
    <v-row class="mt-5">
      <table id="walletTable">
        <tr>
          <th>#</th>
          <th>الباقة</th>
          <th>تاريخ الاشتراك</th>
          <th>حالة الاشتراك</th>
          <th>الفاتورة</th>
        </tr>
        <tr>
          <td>1</td>
          <td>غداء عمل</td>
          <td>2023-03-02</td>
          <td>فعال</td>
          <td style="text-decoration: underline">استعراض الفاتورة</td>
        </tr>
        <tr>
          <td>3</td>
          <td>غداء عمل</td>
          <td>2023-03-02</td>
          <td>فعال</td>
          <td style="text-decoration: underline">استعراض الفاتورة</td>
        </tr>
        <tr>
          <td>4</td>
          <td>غداء عمل</td>
          <td>2023-03-02</td>
          <td>فعال</td>
          <td style="text-decoration: underline">استعراض الفاتورة</td>
        </tr>
        <tr>
          <td>5</td>
          <td>غداء عمل</td>
          <td>2023-03-02</td>
          <td>فعال</td>
          <td style="text-decoration: underline">استعراض الفاتورة</td>
        </tr>
        <tr>
          <td>6</td>
          <td>غداء عمل</td>
          <td>2023-03-02</td>
          <td>فعال</td>
          <td style="text-decoration: underline">استعراض الفاتورة</td>
        </tr>
        <tr>
          <td>8</td>
          <td>غداء عمل</td>
          <td>2023-03-02</td>
          <td>فعال</td>
          <td style="text-decoration: underline">استعراض الفاتورة</td>
        </tr>
      </table>
    </v-row>
    <v-row>
        <v-col class="py-3" :cols="3">
        <v-btn class="mx-5 my-5" style="position:absolute;left:5px;background:#107560 !important;color:#fff;border-radius:8px;font-weight:bold;" color="success" dark>
          اشحن محفظتك الآن
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        package_id: null,
        delivary_start_date: null,
      },
      packageList: [
        {
          id: 1,
          name: "غداء عمل",
        },
        {
          id: 2,
          name: "الباقة الثانية",
        },
        {
          id: 3,
          name: "الباقة الأولى",
        },
      ],
    };
  },
};
</script>

<style>
#walletTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#walletTable th {
  background: #dfeff1 !important;
  color: #000;
}
#walletTable td,
#walletTable th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 8px;
}
#walletTable td {
  color: brown;
}
</style>